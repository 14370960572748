import React, { Component } from "react";
import { Link } from "react-router-dom";

class GalleryView extends Component {
	state = {
        galleryDir: "/images/gallery/"
	};
 
	render() {
		return (
				<div className="gallery-container">
					<div className="gallery-img">
							<Link to={{pathname: '/galleryphotos'}}
								state={{ galleryname:  this.props.galleryname,
												gallerytag: this.props.gallerytag,
												photos: this.props.photos}}
									className="link">
								<img src={process.env.PUBLIC_URL + this.state.galleryDir + this.props.pic} alt="" />
								<div className="gallery-text">
									<h2>{this.props.galleryname}</h2>
								</div>	
							</Link>
					</div>
				</div>
		);
	}
}

export default GalleryView;