import React, { Component } from "react";
import { Link } from "react-router-dom";
import { galleryData } from "../../data";
import Subheader from "../Header/Subheader";
import GalleryView from "../SubComponents/GalleryView";

class Gallery extends Component {
	render() {
		return (
			<div>
				<div>
					<Subheader title="Gallery"/>
				</div>			
				<div className="content-wrapper">
					<div className="gallery-subtext">All gallery images are available as prints. <Link to="/services" className="">Request here for pricing.</Link></div>	
					<div className="gallery-wrapper">
						{ galleryData.map((data, key) => {
							return (
								<GalleryView key={key} pic={data.coverphotouri} galleryname={data.galleryname} photos={data.photos} gallerytag={data.gallerytag}/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
};

export default Gallery;