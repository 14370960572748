import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '../styles/App.scss';
import NavBar from "./Header/NavBar";
import Footer from "./Footer/Footer";

import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Fabricate from "./pages/Fabricate";
import Code from "./pages/Code";
import Print from "./pages/Print";
import Ignite from "./pages/Ignite";
import Illuminate from "./pages/Illuminate";
import Experiment from "./pages/Experiment";
import About from "./pages/About";
import Services from "./pages/Services";
import GalleryPhotos from "./pages/GalleryPhotos";

function App() {
  return (
    <div className="App">
      <Router>
      <NavBar />
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/print" element={<Print/>} />
          <Route path="/fabricate" element={<Fabricate/>} />
          <Route path="/code" element={<Code/>} />
          <Route path="/ignite" element={<Ignite/>} />
          <Route path="/illuminate" element={<Illuminate/>} />
          <Route path="/experiment" element={<Experiment/>} />
          <Route path="/galleryphotos" element={<GalleryPhotos/>} />
        </Routes> 
      <Footer />
      </Router>
    </div>
  );
}

export default App;
