import React from "react";
import { Link } from "react-router-dom";

function FooterNav () {
    return (
            <div className="footer-nav">
                <div className="footernav-left">
                    <div className="footernav-element">
                        <Link to="/services" className="link">
                            services
                        </Link>
                    </div>
                    <div className="footernav-element">
                        <Link to="/gallery" className="link">
                            gallery
                        </Link>
                    </div>
                    <div className="footernav-element">
                        <Link to="/print" className="link">
                            print
                        </Link>
                    </div>
                    <div className="footernav-element">
                        <Link to="/fabricate" className="link">
                            fabricate
                        </Link>
                    </div>
                    {/* <div className="footernav-element">
                        <Link to="/code" className="link">
                            code
                        </Link>
                    </div> */}
                </div>

                <div className="footernav-right">
                    <div className="footernav-element">
                        <Link to="/ignite" className="link">
                            ignite
                        </Link>
                    </div>
                    {/* <div className="footernav-element">
                        <Link to="/illuminate" className="link">
                            illuminate
                        </Link>
                    </div>                 */}
                    <div className="footernav-element">
                        <Link to="/experiment" className="link">
                            experiment
                        </Link>
                    </div>
                    <div className="footernav-element"><Link to="/about" className="link">
                            about
                        </Link>
                    </div>
                </div>
            </div>
    );
}

export default FooterNav;