import React, { Component } from "react";

class ServicesImageView extends Component {
	render() {	
		return (
			<div className="services-image-container"><img src={this.props.servicesimguri} alt="" /></div>
		);
	}
}

export default ServicesImageView;