import React, { Component } from "react";
import CodeProjectView from "../SubComponents/CodeProjectView";
import { projectData } from "../../data";
import Subheader from "../Header/Subheader";

class Code extends Component {
	render() {
		return (
			<div>
				<div>
					<Subheader title="Code / Generative / Algorithmic Art"/>
				</div>
				<div className="content-wrapper">
					<div className="code-projects-wrapper">
						{ projectData.filter(project => project.projecttype === "code").map((data, key) => {
							return (
								<CodeProjectView key={key} 
										pic={data.pic} 
										repository={data.repository} 
										description={data.description} 
										projectname={data.projectname} 
										projectrepo={data.repository} 
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
};

export default Code;