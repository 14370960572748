import React from "react";
import { Link } from "react-router-dom";

function NavBar () {
    return (
        <div className="navigation">
            <div className="nav-home">
                <Link to="/" className="link">
                <div className="nav-home-image"></div>
                <div className="nav-title"><h2><span className="title-span">blue</span> sky catastrophe</h2></div>
                </Link>
            </div>
            <div className="nav-holder">
                <div className="nav-link-services">
                    <Link to="/services" className="link">
                        services
                    </Link>
                </div>
                <div className="nav-link-gallery">
                    <Link to="/gallery" className="link">
                        gallery
                    </Link>
                </div>
                <div className="nav-link-print">
                    <Link to="/print" className="link">
                        print
                    </Link>
                </div>
                <div className="nav-link-fabricate">
                    <Link to="/fabricate" className="link">
                        fabricate
                    </Link>
                </div>
                {/* <div className="nav-link-code">
                    <Link to="/code" className="link">
                        code
                    </Link>
                </div> */}
                <div className="nav-link-ignite">
                    <Link to="/ignite" className="link">
                        ignite
                    </Link>
                </div>
                {/* <div className="nav-link-illuminate">
                    <Link to="/illuminate" className="link">
                        illuminate
                    </Link>
                </div> */}
                <div className="nav-link-experiment">
                    <Link to="/experiment" className="link">
                        experiment
                    </Link>
                </div>
                <div className="nav-link-about">
                    <Link to="/about" className="link">
                        about
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default NavBar;