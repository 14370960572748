import React from "react";

function CodeProjectView(props) {
		let repoDivLink;
		
		if (props.repository) {
			repoDivLink = <div className="code-project-name"><a href={props.repository} target="_blank" rel="noreferrer">{props.projectname}</a></div>
		} 
		
		return (
			<div className="code-project-container">      
				<div className="code-project-img">
					<img src={props.pic} alt="" referrerPolicy="no-referrer"/>
				</div>
				{repoDivLink}
				<div className="code-project-description">{props.description}</div>
			</div>
		);
}

export default CodeProjectView;