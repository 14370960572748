import React, { Component } from "react";
import { servicesData, servicesImageData } from "../../data";
import * as emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha'
import Subheader from "../Header/Subheader";
import ServicesImageView from "../SubComponents/ServicesImageView";
import { randomNum, randomSort } from "../../Helpers";


const RECAPTCHA_SITE_KEY = "6LeCaYsqAAAAAKdvWrI-AmWIi2BNinyr4AQuI-E_";

class Services extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			services_form_email: "",
			services_form_name: "",
			services_form_message: "",
			services_form_details: "",
			recaptchaLoad: false,
			isVerified: false,			
			servicesDir: "/images/services/"
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.resetForm = this.resetForm.bind(this);

		this._reCaptchaRef = React.createRef();
		this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
		this.verifiedRecaptcha = this.verifiedRecaptcha.bind(this);
		this.asyncScriptOnLoad = this.asyncScriptOnLoad.bind(this);
	}
	
	// Form events
    handleInputChange(e) {
		this.setState({ [e.target.name]: e.target.value});
    }
	
    handleSubmit(e) {
        e.preventDefault();	
		this.setState({ isVerified: true });
		const { isVerified } = this.state;
		console.log(this.state);
		
		if (isVerified) {
			console.log("Captcha verified");
			const templateParams = {
				from_name: this.state.services_form_name,
				from_email: this.state.services_form_email,
				reply_to: this.state.services_form_email,				
				to_name: 'Blue Sky Catastrophe',
				subject: '[Site] Request for information',
				message_html:  this.state.services_form_details + this.state.services_form_message,
			};
			
			emailjs.send(
				'service_po6eyx8',
				'template_08aff1g',
				templateParams,
				'user_K5v0XUnYTEEOnzzXxQAWP'
			);
			
			this.resetForm();
			console.log("Submit handled");	
		} else {
			console.log("Submit was not successful");
		}
    }

	resetForm() {
		this.setState({
			services_form_email: "",
			services_form_name: "",
			services_form_message: "",
			services_form_details: ""
		});
	};

	// ReCAPTCHA events
	onChange(value) {
		console.log('Captcha', value);		
	}
	
	recaptchaLoaded() {
		console.log("recaptchaLoaded");
	  	this.setState({ recaptchaLoad: true });
	}

	verifiedRecaptcha(response) {
		console.log("verified recaptcha");
		if (response) {
			this.setState({ isVerified: true });
		}
	}
	
	asyncScriptOnLoad = () => {
		this.setState({ callback: "reCaptcha called!" });
		console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    };


	// Random image tiling
	numArray = () => {
		let nums = Array.from(Array(8).keys());
		nums.map(x => x);
		return nums.sort(randomSort);
	}

	getServicesImages = () => {
        let content = [];
        let nums = [];
        let i = 0;
		let numSet = this.numArray();

        do {
            const randVal = randomNum(9);
            content.push(<ServicesImageView servicesimguri={process.env.PUBLIC_URL + this.state.servicesDir +  "slice_" + i.toString() + "_" + servicesImageData.images[numSet[i]].image}/>);
            nums.push(randVal);
			i++;
        }
        while (i < 8);

        return content;
    };
	
	// Render function
	render() {
		return (
			<div>
				<div>
					<Subheader title="Services"/>
				</div>			
				<div className="content-wrapper">
					<div className="services-wrapper">
						<div className="services-container">
							<div className="services-text">
								<p>{servicesData.servicescontent}</p>
							</div>
							<div className="services-form">
								<form onSubmit={this.handleSubmit}>
									<input 
										type="text" 
										placeholder="name" 
										name="services_form_name"
										onChange={this.handleInputChange}
										value={this.state.connect_name}
									/>
									<input 
										type="text" 
										name="services_form_email"
										placeholder="email address" 
										value={this.state.connect_email}
										onChange={this.handleInputChange}
									/>									
									<select value="" name="services_form_interest" onChange={this.handleInputChange}>
										<option value="">- [Indicate your area of interest]  -</option>
										<option value="service_photo">I'm interested in photography services for an event.</option>
										<option value="service_installation">I'm interested in having one of your installations at an event.</option>
										<option value="service_digital_fabrication">I'm interested in CNC/digital fabrication services for an idea or project.</option>
										<option value="service_collaboration">I'm looking for assistance/collaboration with a custom project. </option>
										<option value="service_commissioned_project">I'm interested in commissioned work for a project or idea.  </option>
										<option value="purchase_photo">I would like to purchase a print that's for sale.</option>
										<option value="purchase_artwork">I would like to purchase art work that's for sale.</option>
									</select>
									<textarea
										type="text" 
										name="services_form_message"
										placeholder="message" 
										onChange={this.handleInputChange}
										value={this.state.connect_message}
									/>
									<button type="submit">Send</button>
								</form>
							</div>
							<div className="services-captcha">
							<ReCAPTCHA
								style={{ display: "inline-block" }}
								theme="dark"
								sitekey={RECAPTCHA_SITE_KEY}
								ref={this._reCaptchaRef}
								onChange={this.onChange}
								verifyCallback={this.verifiedRecaptcha}
								asyncScriptOnLoad={this.asyncScriptOnLoad}
							/>
							</div>
						</div>
						<div className="services-images-wrapper">
                             {this.getServicesImages()}
						</div>
	
					</div>
				</div>
			</div>
		);
	}
}

export default Services;