import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProjectView from "../SubComponents/ProjectView";
import { projectData } from "../../data";
import Subheader from "../Header/Subheader";

class Illuminate extends Component {
	render() {
		return (
			<div>
				<div>
					<Subheader title="LED Art"/>
				</div>
				<div className="content-wrapper">
					<div className="projects-subtext">Interested in having LED art at your event? <Link to="/services" className="">Inquire here for services and pricing.</Link></div>					
					<div className="projects-wrapper">
						{ projectData.filter(project => project.projecttype === "illuminate").map((data, key) => {
							return (
								<ProjectView key={key} 
										pic={data.pic} 
										buildlink={data.buildlink}
										repository={data.repository}
										description={data.description} 
										projectdate={data.projectdate} 
										projectname={data.projectname} 
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
};

export default Illuminate;