import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './Components/App';

ReactDOM.createRoot(document.getElementById('root')) // createRoot(container!) if you use TypeScript
        .render(
          <React.StrictMode>
            <App />
          </React.StrictMode>
        );

