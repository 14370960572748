export const galleryData = [
	{
		galleryname: "Fire Performance",
		tag: "fireperformance",	
		coverphotouri: "_DSC1017.jpg",
		visible: true,
		photos: [
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEVN2xBouswZnvwwz4TrQANFOakaQfY7CMINo3nEd0XEY6KwbTd9aH5QxIRU2mMeIljAfUq_s3M7pSyxilamGrxoDd8BmfI5BAivL0rNKHpiIDtonFNcOsIA11sLqHMJMZGTn9fmfs2CLeEH63r1C_nr=w2793-h1861-no?authuser=0",
			   photoname: "Telluride Fire Festival", 
			   photodate: "December 2019",
			   photolocation: "Telluride, CO"
			},			
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEVLCARRpgrxGtXUQb6oYv85L9-Zu8qsXIqZs2aY3LVt8f0PP5qDSM93KeC2jKjTcSV8bFnZ_orft-UIahWlWOkEVgPcQ71lUHu5Nv_PeLgj-JnJof6lkv7HIen1t87PcYgboZ6K0iReUCCjxF8EmhhN=w2327-h1861-no?authuser=0",
				photoname: "Telluride Fire Festival", 
				photodate: "December 2018",
				photolocation: "Telluride, CO"
			 },
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXVBJmquguCoeQ363uVW7Y2TxWrnjzA0DqwOD8rLJiubqFjvY7u3hnzKYDp7fju_efzEkCXA-GdXfK4UDebterOB30Qb1gjbh-mv03uO0lrOA_ILp6M3qb5rGbhzWbJQLY_rDpSk-Wg9alowVUrzpHu=w2793-h1861-no?authuser=0",
				photoname: "Telluride Fire Festival", 
				photodate: "December 2019",
				photolocation: "Telluride, CO"
			 },
		]
	},
	{
		galleryname: "Colorado",
		tag: "colorado",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEX44VyV-ORdK-X1ubNhsKCWpO6Ml_hiOhYYTnQqW1-RrMLB62wKe3h7maOUursV4rwJ4_UJvyPbFA-JYFTvmu9qMuzbBioAm_nls5znTJ5WzqKKhAtPA11DWJBnMkkWlQZo4CsfGaLvOyJJBd2bD9fZ=w3461-h1947-no?authuser=0",
			   photoname: "The Dunes at Night", 
			   photodate: "June 2022",
			   photolocation: "Great Sand Dunes NP"
			},			
			{
			   photouri: require('./img/Placeholder_photo.jpg'),
			   photoname: "Coming soon", 
			   photodate: "",
			   photolocation: ""
			}

		]
	},	
	{
		galleryname: "14ers",
		tag: "fourteeners",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEWmEGdS6aAQ1jv6Rs4uLH7x349xWHAIcjQ_DQukjgANAEHfwUIFhb84iej0cvIsEDm8nfRuElfiwlVxYXD8vhdqoPYnYBssbPV-RtbuovOzo5S3sUHH0rnw9MxcFBu5vlY6E9RvNU0x-AjM0tKw50zu=w3461-h1947-no?authuser=0",
			   photoname: "Sawatch Mountains in Winter", 
			   photodate: "January 2019",
			   photolocation: "La Plata Peak, CO"
			},			
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXe2z03iwbA9mjlVMERpWZiU_7KKgpnhayQ2Gk3EypzxLjKDNnWd3q2mUNQNNHw7keJ0v7Gc7xK6vkrV8-U5sQfGZYvoUKAdSUrO_ZEQQe6aX-w0KbX9juFslZNn-tpZp0I-BFEoSBv9va_6F_sj-p2=w3461-h1949-no?authuser=0",
			   photoname: "Decalibron Sunrise", 
			   photodate: "July 2018",
			   photolocation: "Tenmile Range, CO"
			},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUN0cZmLG7O0KcLzqJRfoMm2R_gsAUfDP7KbYJsHNqMBd1LU7iaVTXXC5SSzULnEWUeHxJEygobrivQO3VtrKcON4V76Zcw4WJEAGfwZcZlQ3TpyPAoxzcWjjXqc-A9FF4XM-vnQZ59H2xksN7Kp6Tr=w3461-h1947-no?authuser=0",
				photoname: "Little Bear Peak", 
				photodate: "July 2019",
				photolocation: "Como Lake, CO"
			 },				
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXkFg-USNwOyeV5bv_XAwXC3sU1YvdgDTuK9ak41GTwADuV2nyK63nXIqNIwOs3_q3GWfogXuo4Teaid7naWDeyezhBPsOfn5iBYoksDfCryeVbE4Q2e2ysiXlHV5KzNZthgrvPOkwpYZTPnWyKvZrX=w3461-h1947-no?authuser=0",
				photoname: "Sunrise from Humboldt Peak", 
				photodate: "February 2020",
				photolocation: "Westcliffe, CO"
			 },			
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEVfhKdqklSwRFBn9W9-wcLQt6PBopRPrirTWC0JjAsaFRKgdO4hlx3-xP6hB3zLaKGmQYDsJYULC5fkmNNPDTxYEYbEZeZFOjFGLP1tVwHsxfVVzflPGRSZB5cAn_HnH02zv1Kz7PnwSkAHY5NdiPj1=w3841-h1922-no?authuser=0",
				photoname: "Cloud Inversion from Pikes Peak", 
				photodate: "June 2020",
				photolocation: "Colorado Springs, CO"
			 },
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUkuwzjCmmH3Z8FS22qZ33_03gaP6GEwwVDY-FINTvuJLbcFTLx419C8gioBNO7TgOdrP2qhg51dH7C4zTxmECLDLI7A8hmzl90OeF8KnCmLsUCfVPsnzM5jt4gavB5nniuLTC5VqQCqXxG6L-m93-j=w3841-h1281-no?authuser=0",
				photoname: "The Elk Range from Castle Peak", 
				photodate: "May 2020",
				photolocation: "Maroon Bells-Snowmass Wilderness Area"
			 },
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEW87WQ36uSOfOCmA5vsx54VT4B77OqIxPwB99ftOHOmRMhpKolnMliIYVEivkZvwRVFjgroy-Ck3fxAz6E4Zsr0qyYIrxU1nTBp1mF5M8UIINAI7Fpgj4fF3wdwLBBg7NIxI2rvLhdHiqwztuEgTpe4=w3337-h1947-no?authuser=0",
				photoname: "Moonset over Mt. Missouri", 
				photodate: "July 2020",
				photolocation: "Collegiate Peaks, CO"
			 },
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEWGURQSBgu-ESi0XlFtdzhPDsGvzU3Oxeuv71pw1g_0Xr__YAhcl9C0rpnAH-XU81yq7xMJ3gzD7_mypHMlHbuNa99o-D4dvHsRkXU4-0hbkDh6A7z9HOsQUc9o6DpwyhcFg-DJcGi5H4-IGra1YktK=w3461-h1947-no?authuser=0",
				photoname: "Satan's Ridge from Mt Snowmass", 
				photodate: "June 2021",
				photolocation: "Maroon Bells-Snowmass Wilderness Area"
			 }					 	
		]
	},
	{
		galleryname: "Switzerland",
		tag: "switzerland",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUU7TD91y40LaaMMd-Chm8Et07dqDAGkbaGvmAGM3m3LICwQKhYsHEM4cjKJEhDsEsXYKstmdCQgrXEKPkcYJqB96q9nViY8pcWDy2IFMOcMZx2stR-RJxd5SycKqDEcsw2mqMCHuqFUIT_8KJsmdVy=w3461-h1947-no?authuser=0",
			   photoname: "Mountains near Leysin", 
			   photodate: "January 2020",
			   photolocation: "Leysin, Switzerland"
			},			
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXD7i45IN-n2mgrQ1hZIKt1glOqf3dhAiVa9vMG8nUvkkyHasehlqAdF0rq5cJXdWVRXE7G2Q0WOjDgY-xXGArDcUzOB0ENQ43xVVIJLjcnOTiPMyuRySaVR55Nz9pkYJbk9xRpSMVmJWbOG9-72yNJ=w2921-h1947-no?authuser=0",
			   photoname: "Leysin in Winter", 
			   photodate: "January 2020",
			   photolocation: "Leysin, Switzerland"
			},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUB1YADUUIz7SAhUWG2PclFnce4SMVymQE7QzEEX662xdCeUFN9lFwFQaLSqDg_3bNUvvidEvk0c2K6wrT3ZZYwuWTvBh_XixaMDEFTc4w9HTkNkhkt7RNUr4FMvYO8Tj9YrF-a-9Iu59JoTgY7jkQa=w2921-h1947-no?authuser=0",
				photoname: "The Matterhorn at Night", 
				photodate: "January 2020",
				photolocation: "Zermatt, Switzerland"
			 },			
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXLrYE6Zl_PoSA51naVmw-a4Z46RP_WLs6DpXlQnGzCGtjz8VzbV2jap0a9MiNmeQ5ZU4HUeepnft2ahfPZjt7w7omf3aq1duGMC234VRx_NIOjEs38wGzIP0dvXYPniM0NJOZA2RBQP3hP1q37uu5v=w2921-h1947-no?authuser=0",
				photoname: "Zermatt at Dawn", 
				photodate: "January 2020",
				photolocation: "Zermatt, Switzerland"
			 },
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEWNXkwPvcZ5aCiylLUySsQK-3e-AGGHl_idTQCLNcDlPdTB_WBH2hiCjHtXfF7lmU0dmvP9IbZ2vDMgyuY0XErsNKo5_eH4mIezYu_tIEoD84rlydjiGYecjeFSTxPDeWRYquyHvpf50fDYWIYV7INd=w3841-h1922-no?authuser=0",
				photoname: "The Matterhorn from Mt Breithorn", 
				photodate: "January 2020",
				photolocation: "Zermatt, Switzerland"
			 },			
			 {
				photouri: "",
				photoname: "Coming soon", 
				photodate: "",
				photolocation: ""
			 }
		]
	},
	{		
		galleryname: "Utah",
		tag: "utah",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [
			{
			   photouri: "https://lh3.googleusercontent.com/gKZ3ZoKIX3l047f9T3zVRee2V-xwg4-2ERVq2ki26-exQRw5f8BHTwyIAh6lv9I4jb8PZjc661gxHJcH6fa_2NrRyEXoRJ5-38Rq2F_fX5tOWAF0h9HYlbYkWN4kVM5OxjwrQGd6ZNHtU4ygKt03CVyB42_27ltRkUOI3ywFjIc4pGjZ3hfJ_YyFVB1sP2jPLUWijVDaLlemHbGCsSg-itoSVWcWOZzYJ_fwmBTtKN9CyJtG7Q_9YdnTsZkYObaa6C136pYfZiIdqCNKoEU-K9nyHQbW9FkAzS__jZA_kXBXL-rUm4HIGQ98rDt8kVHA5hqL_8hTvWNv_dfqsaj-WVM7u1CUIlvOCMFcMFweR1c2WGnT1LSBFphj_tYl05vj_B8TqUeUD9vSI_8MsQgnpAY1h-0ShRg6IQ9R3yFs2-k266O7JFLrV-Z8812Xo01u1yMBA7GNNWRQV-OQtu7QmqbzIO4-Byct084uBOiAay6n_g3rCq9JRqv2ep7vNZh-7I7l3vF09uavtKeN8PS-k9V8rJB12rfxThXpyqPugWc9DQPgUjdbxZQO-V_Z9R-Af60igqYLfH1Bq0sspgQ2-iBXtlQcCprVJ3Lfbu02ELsHOv407OR29y0HgLkY2u2iIe52W6STfr9_d7CV4kCKAaFk8eFf0sPKT2Df368XQDCRVCzFPp71C_TAsezp0zLZrYe8CF8ejdrSWeM-C7w2KDE_FMfblxbLtykgDKXddhnBkVA-=w709-h399-no?authuser=0",
			   photoname: "Sand dunes, GSDNP", 
			   photodate: "September 2016",
			   photolocation: "Great Sand Dunes National Park, Mosca, Colorado"
			},			
			{
			   photouri: "https://lh3.googleusercontent.com/W12p4FgVFOlBKPgrFJiFqAijZe-PU7FdXehF-jawKNyGDP6JcZblFPxI9Z924ZtT-i4SDPnnHb61krQ7AG8eJ_L1GMZRjZm1zq8lQWk7XJjeXNBFPY-z7d5rf7rsTK6zVF4rG8bJKCVTEcvJg8II1xKFbwfMy-rGMdqrdH4QBwNREF8kcL64Kn3y6VI7gksDL4_g70c9cTmVxUee_UEIAOBJJ1S_xlrsgwxCvLNZsnrdKdtS9TV5ddE-Nm9u2X8KksFGG-IYenhR_NEBPGGtQkXAKfRupgx813q1V5sqz7G_8BgavMYU2FIDYCRld7lhYbxs2m7yQ0e2dZvVqEvj7qtv3a8Mk4prV3n2V_z5b0TJabKxqexJEqr4DWLFMSzBdDKEqGEq4poiup9mXAfN-m24f8EMY6UFt6X_g_zxG0EQha5XkVbBIK7X7rSL8wwqyLEcLU1ocGmC1fexVHuoyv8CZ5XXQ1q_RswAl7jyrlcBQJ953q6w398J5xUsp_PYIf_1FY8DjM7dQhxTRVkyK75jruYzXjOIb4u1A7AU77j6eKNhrvwWB4rHCx24aZea4HXoyMww86jHDHOHDf9L8YfMMZUnVHSM-5q2l7-_ZS9JXl1-5H0ua9IIbbD8ap2LjWN9kTQpNVyi18KCkZ9ATqy3uzesuDebZ5JdYo_iqnjQ3eJhs9yhxrETYCz2yJ-7xAjXpBUqperjM-hMlMhCNSXIUejic-8Hyj7HOw6dQoMj8Kzj=w690-h296-no?authuser=0",
			   photoname: "Buttes In Sedona", 
			   photodate: "November 2016",
			   photolocation: "Sedona, Arizona"
			},			
			{
			   photouri: "https://lh3.googleusercontent.com/sV28RtGWlNNB4sKLrwn3bjFXFWvVCR6aRPmbW0ATUnpO1-BX91skpxOJO-iplt_MJkOtdRVrsHHP5ArcVvJEX2cfmGWeT5cHAt8Qxp_uWtIKsdOq5-IDjZItlo8egBmfOmzWJVW6DX2oQ_qhZ9ssX7FUXqxOY1iNwPGXqMdDkzrsV827C13ar82rDb_Vr-uvE37jkEeTiIeS2XSCR04N_464ljDH4oqnk1WEQXrTirEXRROv1tIFDy0Me47efh-k0tMzN5O9UxRbx71yRgmX_VHlvYSUR19k7RN3moK4Crm5SGs4O2kBUP4kMG2sb0k8xhKlr-qlZrgTcSVpjnRCVMuuDPCXBHo4v8DVklOnPK1qk1Xy8CauiYGMnLiURAph4lo5H-puG1i0xM0FjK1VPsfZTnuhVo4Gxh46zCxgbW7UmRYsZNQOTLmFZpKCYxNQsWWYXlEc21LKfl5OL9nYbKotDwFOp2ftyAynbWrNrz2XUa-cFmJsUim0-Ku4gXhKHdYgh1QNNhRD5MH3vqG7G3BcxTs3am0F0A__ZI5tNhrFYEOQVlo57eG6LQEd5bImwVTdip4WOVimxpMqNmY4Sgi6Ed2U3B9ExV6PvGsAwZltats-5TILBknQurYCBwAuVVLGk1vNmmYXetEQu-0mLG3SM6IQ992y-_X1Q2gDwyLHp1VRo4Iy5UQYZvDbtiFYj4upE1t796RbUPsr8H8kaWCJuK-gNs4O_psnirRfQJMTLSiN=w700-h410-no?authuser=0",
			   photoname: "Night sky over Okefenokee Swamp", 
			   photodate: "November 2018",
			   photolocation: "Okenfenokee National Wildlife Refuge, Folkston, Georgia"
			},	
			
			{
			   photouri: "https://lh3.googleusercontent.com/KOiwCziHwMlnGT-v8slP82PVyl9xF06yaPEiokPCbi3w88RZzm9eJujbjfpzWsw84AOul_UFYVgmfYUaFDAxFmahImH6iBdc5IvJJP666iD1e9PbOZxvzijgmqITq2qmg9ECisKPbHgTfU0gNWTTiornCT8p59bYTXeE8xqjKo6ZsTYB015KgSvkYdGeQQY3kiELDkPrjwa55w3bBk4EutW0fEjaNLh6Q9BuoEbQVbPnzRbNJsUhWKBVxAX4pQJ4Hdl8WtdInlLBkNn7LY_1nMu1nItd4HhdBSMdsayLfK-HmRF69mdEACwCoqT79WilXVHEnBnX3PznM5DXznW9sBwJfUd_PEulPkOcLE1Q913wJGZ4nwNrs5GT-Js-Q9vKMlqMvqtYe4Ymd0Q-vOCXtFjPSlPFngndKANIdUJkSbhghtMdtCsiejIO3X6r_rD3DlFglTvglUeyaMUsyry8SzEGRkZiegvM862O4I9_LXxXb8ZF6CTOvF_OkovA7WdkWyha7NCRH9ucU3gqaShz2Du1Cb2mruxxguyygdubJ85UTGPnltBInT5OdgDe4k-NhFwA9VLaCs8qFUOIBUVP1ujha9wmhkl1B6DlPdG1rs5uWzjpIjX8jT0XYdSovpHkEDtZ-ZJUln0KpM4VSlnnk6u-2nbg6tZ1U8xudGeTbMNo41DdLv1TdhTR9_pFkTvmcITugvO8zJwNDIt3qCF77KoN-F2-QwTazTKW04H-irZE_F_1=w700-h394-no?authuser=0",
			   photoname: "Sunset on Antelope Island", 
			   photodate: "July 2020",
			   photolocation: "Antelope Island State Park, Utah"
			}
		]
	},
	{
		galleryname: "Fire Art",
		tag: "fireart",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [
			{
			   	photouri: "https://lh3.googleusercontent.com/pw/AL9nZEU_RqNdVPHb7tv5MvfZZs8BEp-Kc5-aChhu_ORkeVa_i6yg1t6TYRNDlGVYMxalQC0mRsvRgOKTZyfpq7dQa5ALPTVDnboqrq9GFyH7YZFQpADwBaZWn-nnt6R5Hpt8hFumb9xZCw3k_U1FIg5Bxcgl=w2921-h1947-no?authuser=0",
			   	photoname: "Fire ceiling", 
			   	photodate: "July 2018",
			   	photolocation: "Colorado"
			},			
			{
			   	photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXz3i3OJA6apwIRV23jvp9QVLK8gz12h1V5GyNfc0sO_cLYa0rjsV0IPWz32l__6FizwrDD3ok9yTi70hVvoKwXyvDHNmUsSsJPsVEaRp7qySC8OaqF3Miwy6FjL8UwinKLXMm_sQe6C_PqSw4YetYy=s1947-no?authuser=0",
			   	photoname: "Fire strings - flame effect head", 
			   	photodate: "October 2018",
			   	photolocation: "Georgia"
			},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEVY7bCF-sVbO52LJzdfGy3l7_-c9_DYyyjwmogn9gkvwugGTMDYcdhZiEBWqDtn_Vd6TlLh30c7jy9e5R3eGPAMtetJ-QDCV8CG8nLPARGoGRQxCisRrcik2k-Gbp0pv5xZPtoMXK16pTrWTIawAfRy=w1299-h1947-no?authuser=0",
				photoname: "Paphiopendulum - Flower", 
				photodate: "December 2019",
				photolocation: "Colorado"
		 	},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEVAMYBuzPjQsqLNNVejkZskrHB7Wta1_MvKDUB6JAJbKPD-CHFMJNfU8_ZnDUbgzh-464bmt6d_vCbJKxkfufSujgex_Ys6Goncfhkp03uoW0rmXoZ0rI0cNyyeAN2FZ711faFww8fHRuz3WUufXfhy=w2435-h1947-no?authuser=0",
				photoname: "Fire Tree", 
				photodate: "April 2022",
				photolocation: "Colorado"
		 	}			
		
		]
	},
	{
		galleryname: "Iceland",
		tag: "iceland",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [
			{
			   	photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXn3EH6e2W9Zqwnhz_cTx6auCkKebihot_ff37SU3yKQVLF-vCFTPijdSTAUfF7xnkuRrNkMADX5l0WLprBdYj_PmBYWsquQnfCkYTDaNPm1Ftf42b1DULTPvMyTsHUZaDUeqqVhjgl1N_rVJgqxn9k=w3461-h1947-no?authuser=0",
			   	photoname: "Alftavatn Lake from Laugavegur Trail", 
			   	photodate: "July 2021",
			   	photolocation: "Highlands, Iceland"
			},			
			{
			   	photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUh0SWvuOWSnc7zZv4e2MVC4GXg1z_ePfs41Wpakcz_0MX-ogsEcFbrsvVuno8GWX94TpHoe0skZvZ23YuI91VjNbN1zIpC98O0Cdb9_W6dd4oZg7bPnFy1QoaUsWsX_ME-t1h_rfQ1mFusflWwsmSo=w3461-h1947-no?authuser=0",
			   	photoname: "Reynisdrangar Pinnacles", 
			   	photodate: "July 2021",
			   	photolocation: "Reynisfjara Beach, Vik, Iceland"
			},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXCzSn1whuco5N_DGkHVE21GB28efH3YbZ6jhnLjlV-OtOx4RVIf62r4dg7E0KVtfCFpD4wVMwThPw3jnsJ4B9KTdBqJpkHCC8Jz1dYB8AivCNM2_yV3dnApJLSXExZ20UNIMQAopTUUo9AfiCga0Sm=w3461-h1947-no?authuser=0",
				photoname: "Cave at Reynisfjara", 
				photodate: "July 2021",
				photolocation: "Reynisfjara Beach, Vik, Iceland"
		 	},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEWJeMdbUTOP63uCyN9WF-pHyrl9tioF7_Jlsr_t2JN3jHQbFfEfnNN0xL9QIoSROfFl6luic2s1O80CjlhcFaw7Ufx2HaAoo-kIdTA-FcLxiHFtPGBy4js4Ky40Uhj0TXgVmUaTNYU1_w_RYhZhRbFj=w3461-h1947-no?authuser=0",
				photoname: "Skaftafellsfjöll", 
				photodate: "July 2021",
				photolocation: "Vatnajökull National Park, Iceland"
		 	}			
		
		]
	},
	{
		galleryname: "Wyoming",
		tag: "wyoming",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [
			{
			   	photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUcwnx8okJ1e4oIuqD289na8W3_TB_byepLJkFNoqKaLGPfvNmRo5Kcn8BqIbeQAh0-5r5KIiaWIX7SB1xpmsXeiG2-e6hAjoBND31YChk0AUvm-cIs83DzTlWA1oVtIN1tWSrGkzyYrNz-91A9a0dm=w1920-h1080-no?authuser=0",
			   	photoname: "Cirque of the Towers", 
			   	photodate: "August 2020",
			   	photolocation: "Wind River Range, Wyoming"
			}			
		]
	},
	{
		galleryname: "Ice Climbing",
		tag: "iceclimbing",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	},
	{
		galleryname: "Peru",
		tag: "peru",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	},
	{
		galleryname: "Ecuador",
		tag: "ecuador",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	},
	{
		galleryname: "Large Panos",
		tag: "largepanos",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	},
	{
		galleryname: "Astro",
		tag: "astrophotography",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	},
	{
		galleryname: "China",
		tag: "china",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	},
	{
		galleryname: "Nepal",
		tag: "nepal",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	},
	{
		galleryname: "Turks/Caicos",
		tag: "turkscaicos",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	},
	{
		galleryname: "Washington",
		tag: "washington",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	},
	{
		galleryname: "Montana",
		tag: "montana",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	},
	{
		galleryname: "Oregon",
		tag: "oregon",	
		coverphotouri: "Placeholder_photo.jpg",
		visible: true,
		photos: [		
		]
	}
]

export const projectData = [
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczPMhUKi2-FajQy1Fc6tUW7UJJH7IpbEVq6CrgrUB6uqI2ee4uQ37TuzbUiQd6cWt7D1VQuBgmLM5lh6AYNf29K7TJ6LS8BZQZA99zjpRLCx5KTrqrnghdwb9yq-x3wZU6Nel3-NkJhI2YKWQu31w7Uq=w914-h914-s-no-gm?authuser=0",
		projecttype: "fabricate",
		projectname: "Designer Lamp I",
		projectdate: "October 2024",
		buildlink: "https://photos.app.goo.gl/dWdDTj2nBgKdMyS87",
		description: "Material: Basswood, plastic diffuser. Panel design by @stephaniewindashburn",
		availability: "Available"
	},	
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczNEzv7QplV65LPxTuF34s1-OL2iHD1yJJdgyzmVEC1fTt-6jnXKB982FYVC9E3qVuO9SvkrFpjOG6xaKrBLZ75DICryp3ODweVsAgmktsHVTSs6IW57qWA8MWpGNCQWhiFD1cf473CB50ju3erh3hal=w914-h914-s-no-gm?authuser=0",
		projecttype: "fabricate",
		projectname: "Designer Lamp II",
		projectdate: "October 2024",
		buildlink: "https://photos.app.goo.gl/dWdDTj2nBgKdMyS87",
		description: "Material: Basswood, plastic diffuser, LEDs.",
		availability: "Available"
	},		
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczPJw7Di6vsL87A5lfQHr9HHvypI0XfiV3kofmBiVef6oowdiLS4dQYX_T0hUWD_DZmGETEwGlS4NUcYqIt6R8rxRLrpcM4po3wbMU-8mAXSwFCSWgGhP4e93VpkW8e3AotQVGKgMEU4JnY4B0rDN0Nu=w1950-h1949-s-no-gm?authuser=0",
		projecttype: "experiment",
		projectname: "LED Bracers",
		projectdate: "October 2024",
		buildlink: "https://photos.app.goo.gl/oGCWGsYiN5ESdGvp7",
		description: "Material: Black leather, CNC cut design, diffusive plastic, flexible LED panel, labradorite ornamentation",
		availability: "Not for sale"
	},		
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczN2D9EHNMFA3mFG0zay-J6sboBNbu95a0f6ATIsh4bU37d5shsJEAb6gouttm7X1ntcuPh3gBVhzq7QFgpzfiMoKuqwZu7WmJJY8eFir7QfVkwvI7kVlGzpewsQghEC5cJ3Z1OlPThZWzpKuN_LUPoF=w505-h505-s-no-gm?authuser=0",
		projecttype: "experiment",
		projectname: "O Cam All Ye Faithful",
		projectdate: "December 2020",
		buildlink: "https://photos.app.goo.gl/j8jSB3BFanXscUXL7",
		description: "Material: mild steel, band saw blades, climbing gear (temporarily)."
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczNAsJIas1YlWN5cYYPZ-3K5Sioyc3EGZHWWfR0Q7olqLUUoIDBavVISwrj-zEhomyNO_qT11teY41VNBAl5vYskM1UyGADp0tqrb4P-ic-Q5wtjNwY7jMeS7M0my6y8z4BpWc_NeCK-5-14uAINgC3i=w1949-h1949-s-no-gm?authuser=0",
		projecttype: "experiment",
		projectname: "Industrial Media Cart",
		projectdate: "April 2020",
		buildlink: "https://photos.app.goo.gl/eSnhwWiqwrr5DY4C7",
		description: "Material: Mild steel, transmission parts. Heating/bluing process used to produce color effect.",
		availability: "Not for sale"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczMlRbbVPCbnlrhqUf8dnDNlRrEXT-TMj9FBfO0-F0ZMxj4F8R9O4F1aErwCCzM8q-nKkUnUKgEa0nwxmgCRaZwFNGFbelZIcEP0i2fc3Wb3kEZZwCzOtE3SBooztUHLEkDYyKTYKfY2MygE975C826Z=w897-h897-s-no-gm?authuser=0",
		projecttype: "experiment",
		projectname: "Cam shaft table stands",
		projectdate: "January 2019",
		description: "Material: Cam shafts, masonry blades. Heating/bluing process used to produce color effect.",
		availability: "Not for sale"
	},	
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczPcDr1NyFmeSV4i5JocIzMPGacPhvjMikfbTnJgUd8CN4-O1w-wyNPozKKe1vvnl75Qb_jUL1WahexVDpIbdMPU1nJV8gkK3uolzTrHdSnPcGnuxIEDF9Jk89vq3kKigYq1cI6r0I_pSpkGQL1GKTph=w1155-h1155-s-no-gm?authuser=0",
		projecttype: "ignite",
		projectname: "Merrrr-y Go Round", 
		projectdate: "March 2023",
		buildlink: "https://photos.app.goo.gl/5hAtoXNc6k5KJbsL8",
		description: "Flame effect with two freeburning/pilot effects on a rotating platform, encased within a geodesic structure. Mild steel, polished steel paneling, plasma cut base.",
		availability: "Available for events"
	},	
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczNPZkP5NQOSN1lfEuLE6yTX_CYrxuTKhrt5msbGjN8w73SK-yE5cadL8q1MtTYJuYyt3gulqMf6hxQFgHP7rur_zvHwnW8PSMdmUCFkxi4uG5RXyr1MQnJcHLOZYRGYORSIc-8VbBzqZsg1uffUAUuG=w1065-h1065-s-no-gm?authuser=0",
		projecttype: "ignite",
		projectname: "Fire Column", 
		projectdate: "March 2023",
		buildlink: "https://photos.app.goo.gl/3aYtEao4PfqbiVPN9",
		description: "Flame effect with single pilot effect on a tower platform. Mild steel, polished steel paneling for the tower, miscellaneous parts for the base.",
		availability: "Available for events"
	},	
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczOkqDPmGEaH7zfig_4LxgzMfeZCzFda-Cor_0dcapwnvslEa5GBkiW1q4ajxYiEPlXy8kBpEt-kBcYlDMPfTw1buHw-ch60ndS-lb5yudX_0VRkAA62RPAtOE1o9IM2DXz0A_xc9JOrduD8BF-GdauC=w692-h692-s-no-gm?authuser=0",
		projecttype: "ignite",
		projectname: "Fire Zen Garden", 
		projectdate: "March 2023",
		buildlink: "https://photos.app.goo.gl/2154jRLj5PDEioAz5",
		description: "Fire pit composed of steel slag chips/abrasive. Updated from a previous project. Mild steel, plasma cut paneling, steel slag chips, misc metal for use as sand rakes.",
		availability: "Available for events"
	},	
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczNeGaUHx5kV6MMUYU2uxsQkRGQpxv0VikHccFPqgWQ2SH6vXG1DHWQCDGcqNv3-fZjMrtOEgymFuDQsGOBC8bIu579Dnytl5JXk91DdIglPkUg86GhmC1lp05o1VwgAKVCV3Iu8wTJwx_-ueSMU65H3=w692-h692-s-no-gm?authuser=0",
		projecttype: "ignite",
		projectname: "Reflection Erection", 
		projectdate: "December 2019",
		buildlink: "https://photos.app.goo.gl/rMxXpfZ7FRvqUcHU6",
		description: "Pressurized flame effect with two freeburning/pilot effects, actuated by simple button. Mild steel frame with polished/reflective steel.",
		availability: "Available for events"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczPptF3vmwUS8LN8ulXq-yR7Rnol75QPiQr7XJBAWsucVj26gujPFObqI3xW5t1fEfBrdr9nwFHoMHtP4VlU8IBtI-4H3OIjHf8yoQVCIVSePo1k-ioMUN10M5W2MzWX-qzF58Sg1T8Rrr_jit2I36jD=w692-h692-s-no-gm?authuser=0",
		projecttype: "ignite",
		projectname: "Fire Strings", 
		projectdate: "October 2018",
		buildlink: "https://photos.app.goo.gl/4373ucdQ7XrnbWSv5",
		repository: "https://github.com/automorphic/fire-strings",
		description: "Four channel pressurized flame effects actuated by violin string vibrations. Actuation and input smoothing functions are controlled by an arduino Nano.",
		availability: "Decommissioned"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczOtt4ml7n90fNmMy89A2V1rYtzihARMhhqaeF0-c3dTlwRFkgB2rVWSIftxy9jjRHVCL5uwEUayI_zezNd08rSFKvs1aamnjURG5jyTnR9ZpwcGKTtWWFztrWCtkmweiZEBelkwQ1HfiClrM2L1cTk1=w1199-h1199-s-no-gm?authuser=0",
		projecttype: "print",
		projectname: "Fire Art Frame",		
		projectdate: "December 2021",		
		description: "Print: Dye sublimation on metal. Frame: Mild steel, rusted with coating, addressable LED backlighting",
		buildlink: "https://photos.app.goo.gl/RYBdXpwkZwwVisqB9",
		availability: "Not for sale"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczPzd97M0saRLYxvEwX_yLjeTppicL2gZgD8BhEA6_WQRCht3dZ47vYNl7YSpC6CE-XfdLTkv46_BPM-T_y4rLBhVSAYJzMPPYUQOXe8fuROz1p0NnGwXdqX5Xrz_MhfQL9kI3HeYx6v-IEMtaduT-LG=w1222-h1222-s-no-gm?authuser=0",
		projecttype: "print",
		projectname: "Alpine Black and White",		
		projectdate: "June 2021",		
		description: "Print: Dye sublimation on metal. Frame: Wood, LEDs for backlighting",
		buildlink: "https://photos.app.goo.gl/qdczuYod1ZksopKe6",
		availability: "Not for sale"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczPtt67EID6fL1RB8z3NMAjv6jO6DcITZ_4qn94sBLNcqM1nDT25aHrXUXvGsLcNqQjrEGPYFpUfO8jla0Mm0SZz5RC6Jiy8FefGAwyva3clOCvdMgsCMPWhpLJtl0wOk3nsEzBlzm2kavg061-sfMb-=w1199-h1199-s-no-gm?authuser=0",
		projecttype: "print",
		projectname: "Sedona",		
		projectdate: "January 2021",		
		description: "Print: Dye sublimation on metal. Frame: Mild steel, japanese brown patina",
		availability: "Available"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczMBlZ5UNBMVLGtyhlZHvJXMBwXP9oPriPWPawm4piRxygeJkbzbekPRRzoN23G6OrlsNQoqYdKVCHi1vKrmE3oWBSd4JkHyVOyXHkmToorFzvCBKGJW-VNUfnMH8aSXdBcb68hJ3QH2ynFQKC71lzMq=w1199-h1199-s-no-gm?authuser=0",
		projecttype: "print",
		projectname: "The Matterhorn",		
		projectdate: "January 2021",		
		description: "Print: Dye sublimation on metal. Frame: Mild steel, gunbluing patina",
		availability: "Available"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczOGDJF9OSk7YaQJvbNLrgVZzr7bBNZEX3M4WRbt7mz3TpzPJOtEqRw7poq0drMQFDP9eRAxRvCsALYTkLjIFYBQTfv4sjA5pI73ezS7Ran7djCp_WcpPNr6s_MJbgG-VyG_U0lZaNS8WTEEGk4a0gN4=w1199-h1199-s-no-gm?authuser=0",
		projecttype: "print",
		projectname: "Antelope Island",		
		projectdate: "January 2021",		
		description: "Print: Dye sublimation on metal. Frame: Mild steel with patina",
		availability: "Available"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczMYFJ9TnuxIB2qHTnCAYWkyXlpLekOEl8LR0Asl7wmLzvxhBUBgmuXhSUoZzeqJ6HMw9OAZKAo_BEJ2jIanlCQp5CLXozlwaPZTWktPmDGvOpFrFmq--HPro1utjMT4suxHVntP3ksGe1kj8a9KxbgC=w1199-h1199-s-no-gm?authuser=0",
		projecttype: "print",
		projectname: "High Dune - Great Sand Dunes National Par",		
		projectdate: "January 2021",		
		description: "Print: Dye sublimation on metal. Frame: Mild steel with patina",
		availability: "Available"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczPf_ZStJyk8JzIeBkXraM4vj-gI8HOUzbJ_b3K3LKk_CKUxLZl8kYXoFPTrWhgYn3di3gSU9k9HivnwiXR7PuhZvehAcTz-gA01aChfa_b-AJwpxyr6wl3VnT-_uYjSgzS3eTKaIqjEYKONhShZYE7u=w1199-h1199-s-no-gm?authuser=0",
		projecttype: "print",
		projectname: "The Matterhorn and Alps",		
		projectdate: "January 2021",		
		description: "Print: Dye sublimation on metal. Frame: Mild steel, japanese brown patina",
		availability: "Not for sale"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AP1GczP7YJ9AY0p9-VtoxYQMwV14A6TJs7iTWLDPty5elI4xiCa4sKzWsrkydAia5ebFTcu_BHgGMWoLeaa8GzZaGE4nZG_09YCiQLQyzYPwfHbrN8VvxucHb0zb_G3CIHXnppWZ9zWmnoUfTbg2LZoKvJUS=w1199-h1199-s-no-gm?authuser=0",
		projecttype: "print",
		projectname: "LOVE Effigy",		
		projectdate: "January 2019",		
		description: "Print: Dye sublimation on metal. Frame: Mild steel with bluing, red LED backlighting.",		
		buildlink: "https://photos.app.goo.gl/EiMNs8S96nFfvEpc8",
		availability: "Not for sale"
	},	
	{
		pic: require('./img/Placeholder_photo.jpg'),
		projecttype: "code",
		projectname: "Redcloud+Sunshine",		
		projectdate: "January 2022",		
		description: "Custom hardware project for LED effects based on NodeMCU/ESP8266 microcontrollers",
		repository: "https://github.com/Automorphic-Systems/redcloud-led-rest"
	},	
	{
		pic: require('./img/Placeholder_photo.jpg'),
		projecttype: "code",
		projectname: "Polyhere",		
		projectdate: "January 2022",		
		description: "Generative art tool for simple tesselations",
		repository: "https://github.com/splaticvoid/polyhere"
	}		
]

export const aboutData = {
	aboutcontent: "Initially inspired by my experiences at festivals and the spectacles of fire and performance that accompanied them, I picked up a camera many years ago and pursued photography as an enthusiast. I gravitated towards these subjects as they reflected a certain intensity, capturing a certain empheral quality characteristic of the typoe of artistic expression frequently found at these events. In tandem with event photography I began to take my camera on the trail and eventually into the Rocky mountains of Colorado, and in time, various mountain destinations around the world. The awe of being in the presence of something unyielding and austere has been a springboard for inspiration, granting me return from the mountains with new ideas for other creative endeavors.",
	aboutsummary: "Being behind the lens to effectively capture the artistic expression of others ultimately meant becoming better acquainted with their means of expression. I began to explore the application of LEDs, metal, propane/LPG, and techniques of CNC fabrication to produce a number of projects and installations. As I learned how to work with tools surrounding these mediums, ideas began to abound towards how to tie these things together in a more interactive manner to produce more engaging works and installations. Ultimately my art is an experimental, exploratory journey around the confluence of various media and disclipines both artistic and technical, one that continues indefinitely."
}

export const aboutImageData = {
	tiles: [
		{ photouri : "sq_DSC00178-Pano.jpg"},
		{ photouri : "sq_DSC00890.JPG"},
		{ photouri : "sq_IMG_0046-Pano.jpg"},
		{ photouri : "sq_IMG_0436-Pano.jpg"},
		{ photouri : "sq_IMG_20190505_131911.jpg"},
		{ photouri : "sq_IMG_20190729_201946.jpg"},
		{ photouri : "sq_IMG_20190731_094017.jpg"},
		{ photouri : "sq_IMG_4282-HDR-Pano.jpg"},
		{ photouri : "sq_IMG_9759-Pano.jpg"},
		{ photouri : "sq__DSC0102.jpg"},
		{ photouri : "sq__DSC2588.jpg"},
		{ photouri : "sq__DSC2590-Pano.jpg"},
		{ photouri : "sq__DSC2601.jpg"},
		{ photouri : "sq__DSC2838-Pano.jpg"},
		{ photouri : "sq__DSC2887.jpg"},
		{ photouri : "sq__DSC3589-Pano.jpg"},
		{ photouri : "sq__DSC3851.jpg"},
		{ photouri : "sq__DSC6099-Pano.jpg"},
		{ photouri : "sq__DSC8766-HDR.jpg"},
		{ photouri : "sq__MG_6709.jpg"},
		{ photouri : "sq__MG_6738.jpg"},
		{ photouri : "sq__MG_7242.jpg"},
		{ photouri : "sq__MG_7635-Pano.jpg"},
		{ photouri : "sq__SAM5342-HDR-Pano.jpg"},
		{ photouri : "sq__SAM5727-HDR-Pano.jpg"}	
	]
}

export const servicesData = {
	servicescontent: "Interested in a print, artwork, custom project, or creative services? Submit an inquiry below!"
}

export const servicesImageData = {
	images: [
		{ image : "DSC00336.jpg"},
		{ image : "IMG_2764.jpg"},
		{ image : "IMG_4282-HDR-Pano.jpg"},
		{ image : "_DSC0102.jpg"},
		{ image : "_DSC2338.jpg"},
		{ image : "_DSC6130.jpg"},
		{ image : "_DSC7547.jpg"},
		{ image : "_DSC9386-HDR.jpg"},
		{ image : "_DSC9787.jpg"}
	]
}

export const homeData = {
	homeContent: "",
	slides: [		
		{ slideuri : "sq_DSC00178-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq_DSC00336.jpg", slidename : "" } ,
		{ slideuri : "sq_DSC00890.JPG", slidename : "" } ,
		{ slideuri : "sq_IMG_0046-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_0436-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_20190505_131911.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_20190729_201946.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_20190731_094017.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_2764.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_4282-HDR-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_9759-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC0102.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2338.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2588.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2590-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2601.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2838-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2887.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC3589-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC3851.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC6099-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC6130.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC7547.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC8766-HDR.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC9386-HDR.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC9787.jpg", slidename : "" } ,
		{ slideuri : "sq__MG_6709.jpg", slidename : "" } ,
		{ slideuri : "sq__MG_6738.jpg", slidename : "" } ,
		{ slideuri : "sq__MG_7242.jpg", slidename : "" } ,
		{ slideuri : "sq__MG_7635-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__SAM5342-HDR-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__SAM5727-HDR-Pano.jpg", slidename : "" } , 
		{ slideuri : "sq__DSC7868-HDR.jpg", slidename : "" } 
	]
}
