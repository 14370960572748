import React from "react";

function Subheader(props) {
    if (props.ismain) {
        return (
            <div/>                
        );
    } else {
       return (
            <div className="subheader-container">
                {props.title}  
           </div>        
        );
    }
}

export default Subheader;