import React, { Component } from "react";
import { Link } from "react-router-dom";
import ProjectView from "../SubComponents/ProjectView";
import { projectData } from "../../data";
import Subheader from "../Header/Subheader";

class Fabricate extends Component {
	render() {
		return (
			<div>
				<div>
					<Subheader title="Cut / Carve / Engrave"/>
				</div>
				<div className="content-wrapper">
					<div className="projects-subtext">All finished workpieces are for sale unless otherwise noted. Small scale Laser and Plasma CNC cutting services are available! <Link to="/services" className="">Request here for services and pricing.</Link></div>	
					<div className="projects-wrapper">
						{ projectData.filter(project => project.projecttype === "fabricate").map((data, key) => {
								return (
									<ProjectView key={key} 
											pic={data.pic} 
											buildlink={data.buildlink} 
											repository={data.repository} 
											description={data.description} 
											projectdate={data.projectdate} 
											projectname={data.projectname} 
											availability={data.availability}
									/>
									
								);
							})}

					</div>
				</div>
			</div>
		);
	}
};

export default Fabricate;