import React from "react";
import { useLocation, Link } from "react-router-dom";
import Subheader from "../Header/Subheader";

const GalleryPhotos = () => {

	const location = useLocation();
	const propsData = location.state;

	return(
			<div>
				<div>
					<Subheader title="Gallery"/>
				</div>
			<div className="content-wrapper">
				<div className="galleryphotos-header">
					{<h2>{propsData.galleryname}</h2>}
				</div>
				<div className="gallery-subtext">All gallery images are available as prints. 
					<Link to="/connect" className="">Request here for pricing.</Link>
				</div>
				<div className="galleryphotos-wrapper">
					{ propsData.photos.map((data, key) => {
						return (
							<div className="galleryphoto-container">
								<div className="galleryphoto-image" key={key}>
								<Link to={data.photouri} className="link">
									<img src={data.photouri} alt={data.photoname} referrerPolicy="no-referrer" />
								</Link>
								</div>
								<div className="photomodal-details">{data.photoname}<br/>{data.photolocation}<br/>{data.photodate}</div>								
							</div>
						)
					})}
				</div>
			</div>
			</div>
	);
}

export default GalleryPhotos;