import React from "react";

function ProjectView(props) {
		let repoLink, buildLink;
		let projectDir = "/images/projects/";
		

		if (props.buildlink) {
			buildLink = <a href={props.buildlink} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + projectDir + "build_small.png"} alt="Build" /></a>
		} 
		
		if (props.repository) {
			repoLink = <a href={props.repository} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + projectDir + "code_small.png"} alt="Code" /></a>
		} 
		
		return (
			<div className="project-container">      
				<div className="project-img">
					<img src={props.pic} alt="" referrerPolicy="no-referrer"/>
				</div>
				<div className="project-info">
					<div className="project-name">{props.projectname}</div>
					<div className="project-description">{props.description}</div>
					<div className="project-date">{props.projectdate}</div>
					<div className="project-links">{buildLink}  {repoLink}</div>
					<div className="project-available">{props.availability}</div>
				</div>
			</div>
		);
}

export default ProjectView;